import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    const navbarStyle = {
        backgroundColor: '#333',
        color: 'white',
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    };

    const titleStyle = {
        fontWeight: 'bold',
        fontSize: '24px',
    };

    const navListStyle = {
        listStyleType: 'none',
        display: 'flex',
        margin: 0,
        padding: 0
    };

    const navItemStyle = {
        margin: '0 10px',
    };

    const linkStyle = {
        color: 'white',
        textDecoration: 'none',
        fontSize: '18px'
    };

    return (
        <nav style={navbarStyle}>
            <div style={titleStyle}><Link to="/" style={linkStyle}>CompletumHealth AI Sandbox</Link></div>
            <ul style={navListStyle}>
                <li style={navItemStyle}><Link to="/chat" style={linkStyle}>Chat</Link></li>
                <li style={navItemStyle}><Link to="/imaging" style={linkStyle}>Imaging</Link></li>
                <li style={navItemStyle}><Link to="/fitnessplanner-ai" style={linkStyle}>FitnessPlannerAI</Link></li>
                <li style={navItemStyle}><Link to="/mealplanner-ai" style={linkStyle}>MealPlannerAI</Link></li>
                <li style={navItemStyle}><Link to="/anomaly-ai" style={linkStyle}>AnomalyAI</Link></li>
                <li style={navItemStyle}><Link to="/mindful-eating" style={linkStyle}>Mindful Eating</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;
