import React, { useState } from 'react';
import Spinner from '../Spinner';

const FitnessForm = () => {
  const [formData, setFormData] = useState({
    goals: [],
    age: '',
    height: { feet: '', inches: '' },
    weight: '',
    sex: '',
    injuries: '',
    focus: [],
    activity_level: '',
    activity_examples: [''],
    locations: [],
    facilities: [],
    availability: [{ day: '', time: '' }],
    duration: ''
  });
  const [loading, setLoading] = useState(false);

  const [responseData, setResponseData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleHeightChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      height: {
        ...prevData.height,
        [name]: value,
      },
    }));
  };

  const handleArrayChange = (e, index, key) => {
    const { value } = e.target;
    const updatedArray = [...formData[key]];
    updatedArray[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      [key]: updatedArray,
    }));
  };

  const handleAddArrayItem = (key) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: [...prevData[key], ''],
    }));
  };

  const handleAvailabilityChange = (e, index, key) => {
    const { name, value } = e.target;
    const updatedAvailability = [...formData.availability];
    updatedAvailability[index] = { ...updatedAvailability[index], [name]: value };
    setFormData((prevData) => ({
      ...prevData,
      availability: updatedAvailability,
    }));
  };

  const handleAddAvailability = () => {
    setFormData((prevData) => ({
      ...prevData,
      availability: [...prevData.availability, { day: '', time: '' }],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        // Sending formData to your API endpoint
        const response = await fetch('https://chatapi-staging.completumhealth.com/generate-fitness-plan', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),  // Convert form data to JSON
        });

        if (!response.ok) {
            throw new Error('Error: ' + response.statusText);
        }

        // Parse the JSON response
        const data = await response.json();

        // Assuming the response has an 'exercise_plan' field
        setResponseData(data.exercise_plan);
    } catch (error) {
        console.error('There was an error submitting the form:', error);
    } finally {
        setLoading(false);
    }
};



const renderExercisePlan = (plan) => {
    return Object.entries(plan).map(([day, details]) => (
        <div key={day}>
            <h4>{day}</h4>
            {details.activity instanceof Array ? (
                details.activity.map((activity, index) => (
                    <div key={index} style={{ marginLeft: '20px' }}>
                        <p><strong>Activity {index}</strong></p>
                        <strong>Description:</strong> {activity.description}<br />
                        <strong>Duration:</strong> {activity.duration}<br />
                        {activity.type && <div><strong>Type:</strong> {activity.type}</div>}
                    </div>
                ))
            ) : (
                <div>
                    <strong>Activity:</strong> {details.activity}<br />
                    {details.description && <div><strong>Description:</strong> {details.description}</div>}
                </div>
            )}
            {details.duration && <div><strong>Total Duration:</strong> {details.duration}</div>}
            {details.time && <div><strong>Time:</strong> {details.time}</div>}
        </div>
    ));
};



  return (
    <div>
    {      loading ? <Spinner/> :
    
    <form onSubmit={handleSubmit} style={{margin: '20px'}}>
        <h3>Fitness Plan Form</h3>
        {/* Goals */}
        <div>
            <label>Goals</label>
            <input
            type="text"
            name="goals"
            placeholder="Enter goals separated by commas"
            value={formData.goals}
            onChange={(e) => handleChange(e)}
            />
        </div>

        {/* Age */}
        <div>
            <label>Age</label>
            <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            />
        </div>

        {/* Height */}
        <div>
            <label>Height</label>
            <input
            type="number"
            name="feet"
            placeholder="Feet"
            value={formData.height.feet}
            onChange={handleHeightChange}
            />
            <input
            type="number"
            name="inches"
            placeholder="Inches"
            value={formData.height.inches}
            onChange={handleHeightChange}
            />
        </div>

        {/* Weight */}
        <div>
            <label>Weight (lbs)</label>
            <input
            type="number"
            name="weight"
            value={formData.weight}
            onChange={handleChange}
            />
        </div>

        {/* Sex */}
        <div>
            <label>Sex</label>
            <select name="sex" value={formData.sex} onChange={handleChange}>
            <option value="">Select...</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            </select>
        </div>

        {/* Injuries */}
        <div>
            <label>Injuries</label>
            <input
            type="text"
            name="injuries"
            value={formData.injuries}
            onChange={handleChange}
            />
        </div>

        {/* Focus */}
        <div>
            <label>Focus Areas</label>
            <input
            type="text"
            name="focus"
            placeholder="Enter focus areas separated by commas"
            value={formData.focus}
            onChange={handleChange}
            />
        </div>

        {/* Activity Level */}
        <div>
            <label>Activity Level</label>
            <select
            name="activity_level"
            value={formData.activity_level}
            onChange={handleChange}
            >
            <option value="">Select...</option>
            <option value="Light">Light</option>
            <option value="Moderate">Moderate</option>
            <option value="Intense">Intense</option>
            </select>
        </div>

        {/* Activity Examples */}
        <div>
            <label>Activity Examples</label>
            {formData.activity_examples.map((activity, index) => (
            <div key={index}>
                <input
                type="text"
                value={activity}
                onChange={(e) => handleArrayChange(e, index, 'activity_examples')}
                />
            </div>
            ))}
            <button type="button" onClick={() => handleAddArrayItem('activity_examples')}>
            Add Example
            </button>
        </div>

        {/* Locations */}
        <div>
            <label>Locations</label>
            <input
            type="text"
            name="locations"
            placeholder="Enter locations separated by commas"
            value={formData.locations}
            onChange={handleChange}
            />
        </div>

        {/* Facilities */}
        <div>
            <label>Facilities</label>
            <input
            type="text"
            name="facilities"
            placeholder="Enter facilities separated by commas"
            value={formData.facilities}
            onChange={handleChange}
            />
        </div>

        {/* Availability */}
        <div>
            <label>Availability</label>
            {formData.availability.map((slot, index) => (
            <div key={index}>
                <input
                type="text"
                name="day"
                placeholder="Day"
                value={slot.day}
                onChange={(e) => handleAvailabilityChange(e, index, 'day')}
                />
                <input
                type="time"
                name="time"
                value={slot.time}
                onChange={(e) => handleAvailabilityChange(e, index, 'time')}
                />
            </div>
            ))}
            <button type="button" onClick={handleAddAvailability}>
            Add Availability
            </button>
        </div>

        {/* Duration */}
        <div>
            <label>Duration (minutes)</label>
            <input
            type="number"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            />
        </div>
        <button type="submit">Submit</button>
      </form>}

      {responseData && (
            <div style={{margin: '20px' }}>
                <h3>Your Exercise Plan:</h3>
                {renderExercisePlan(responseData)}
            </div>
        )}
    
    </div>
  );
};

export default FitnessForm;
